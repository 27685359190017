import React from 'react';
import ReactDOM from 'react-dom';

import {
  media_device_stream,
  ws,
} from './utils.js'

const DOM_EXCEPTIONS = {
  NOT_FOUND_ERROR: 'NotFoundError',
}

const Main = (props) => {
  const [media_stream, set_media_stream] = React.useState(null)
  const [cameras, set_cameras] = React.useState([])
  const [selected_camera, set_selected_camera] = React.useState(null)
  const [username, set_username] = React.useState('')
  const [target, set_target] = React.useState('')
  const [stun_server, set_stun_server] = React.useState('stun:stun.tmfilm.info')
  const [peer_connection, set_peer_connection] = React.useState(null)
  const [remote_stream, set_remote_stream] = React.useState(null)

  const video_ref = React.createRef()
  const remote_ref = React.createRef()
  const get_stream = async () => {
    if(!selected_camera) {
      return
    }
    try {
      const constraints = {
        audio: true,
        video: {
          deviceId: selected_camera.deviceId,
        }
      }
      const stream = await media_device_stream(constraints)
      set_media_stream(stream)
    } catch(error) {
      if(error.name === DOM_EXCEPTIONS.NOT_FOUND_ERROR && cameras.length > 0) {
        get_stream()
        return
      }
      console.log(error)
    }
  }

  const get_connected_devices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices()
    const camera_list = devices.filter(device => device.kind === 'videoinput')
    set_cameras(camera_list)
    set_selected_camera(camera_list[0])
  }

  const setup_peer_connection = async () => {
    if(!stun_server) {
      window.alert('Missing stun server')
      return
    }
    try {
      const pc = new RTCPeerConnection({
        iceServers: [{
          urls: stun_server,
        }],
      })
      pc.onicecandidate = (event) => {
        const candidate = event.candidate
        if(candidate) {
          ws.send(JSON.stringify({
            iceCandidate: candidate,
          }))
        }
      }
      pc.onaddstream = (event) => {
        set_remote_stream(event.stream)
      }
      await set_peer_connection(pc)
    } catch(error) {
      set_stun_server(null)
      console.log(error)
    }
  }

  const setup_websocket = () => {
    ws.onmessage = async message => {
      const msg_data = JSON.parse(message.data)
      if(msg_data.answer) {
        const remote_desc = new RTCSessionDescription(msg_data.answer)
        await peer_connection.setRemoteDescription(remote_desc)
        console.log('Got answer')
      }
      if(msg_data.offer) {
        const remote_desc = new RTCSessionDescription(msg_data.offer)
        peer_connection.setRemoteDescription(remote_desc)
        const answer = await peer_connection.createAnswer()
        await peer_connection.setLocalDescription(answer)
        ws.send(JSON.stringify({answer: answer}))
        console.log('Got offer')
      }
      if(msg_data.iceCandidate) {
        try{
          await peer_connection.addIceCandidate(msg_data.iceCandidate)
          console.log('Got candidate')
        } catch(error) {
          console.log(error)
        }
      }
    }
  }

  const change_stun_server = async (stun) => {
    await set_stun_server(stun)
    await setup_peer_connection()
  }

  const connect = async () => {
      if(!peer_connection) {
        return
      }
      const offer = await peer_connection.createOffer()
      await peer_connection.setLocalDescription(offer)
      ws.send(JSON.stringify({offer: offer}))
  }

  React.useEffect(() => {
    if(!peer_connection) {
      setup_peer_connection()
    }
    if(peer_connection) {
      setup_websocket()
    }
    if(peer_connection && media_stream) {
      peer_connection.addStream(media_stream)
    }
    if(cameras.length === 0) {
      get_connected_devices()
    }
    if(!media_stream) {
      get_stream()
    }
    if(video_ref.current) {
      video_ref.current.srcObject = media_stream
    }
    if(remote_ref.current) {
      remote_ref.current.srcObject = remote_stream
    } // eslint-disable-next-line
  }, [
      selected_camera,
      video_ref,
      cameras,
      media_stream,
      peer_connection,
      remote_stream,
      remote_ref,
    ])

  if(!cameras || !selected_camera || !media_stream) {
    return <p>Loading...</p>
  }

  return <div style={{
    width: '100%',
    height: '100%',
  }}>
      {selected_camera && <div
          style={{
            marginTop: '1em',
            marginBottom: '1em',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2>Camera</h2>
          <select
            style={{
              marginLeft: '0.5em',
            }}
            value={selected_camera}
            onChange={camera => {
              set_selected_camera(camera)
              get_stream()
            }}
          >
            {cameras.map( camera => {
              return <option
                  value={camera}
                  key={camera.deviceId}
                >
                  {camera.label}
                </option>
            })}
          </select>
        </div>
      }
      <div
        style={{
          marginTop: '1em',
          marginBottom: '1em',
          display: 'flex',
          flexDirection: 'column',
          width: '30%',
        }}
      >
        <input
          value={username}
          onChange={(event) => set_username(event.currentTarget.value)}
          placeholder='Username'
        />
        <input
          value={target}
          onChange={(event) => set_target(event.currentTarget.value)}
          placeholder='Target'
        />
      </div>
      <div
        style={{
          marginTop: '1em',
          marginBottom: '1em',
        }}
      >
        <h3>Server</h3>
        <input
          value={stun_server}
          onChange={(event) => change_stun_server(event.target.value)}
        />
        <input
          type='button'
          disabled={!stun_server}
          onClick={connect}
          value='Connect'
        />
      </div>
      <div style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
      }}>
        <div
          style={{
            width: '640px',
            height: '545px',
            border: '5px solid green',
          }}
        >
          <h2>You</h2>
          <video width='640' height='480' ref={video_ref} autoPlay playsInline muted/>
        </div>
        <div
          style={{
            width: '640px',
            height: '545px',
            border: '5px solid red',
          }}
        >
          <h2>Remote</h2>
          <video width='640' height='480' ref={remote_ref} autoPlay playsInline/>
        </div>
      </div>
    </div>
}

ReactDOM.render(
  <Main/>,
  document.getElementById('root')
);
