const ws = new WebSocket("wss://signal.tmfilm.info:443")

const media_device_stream = async (constraints) => {
  return await navigator.mediaDevices.getUserMedia(constraints)
}

export {
  media_device_stream,
  ws,
}
